import { FormControl, FormGroup } from '@angular/forms';
import { Action, Store } from '@ngrx/store';
import partition from 'lodash/partition';
import { FireflyDrawerContent, FireflyDrawerService } from '@capital-access/firefly/components';
import { LOCALIZATION_KEY } from '../../constants';
import { saveSectionsSettings } from '../../core/+state/report.actions';
import { ReportSection, SectionSelectorListItem } from '../../core/models/report-sections';

export abstract class BaseReportDrawer<T, S> extends FireflyDrawerContent<{
  request?: T;
  filteredSections: S;
}> {
  localizationScope = LOCALIZATION_KEY;
  request?: T;
  commonForAllSectionsSettings: SectionSelectorListItem[] = [];
  disableSaving = false;

  form: FormGroup = new FormGroup({
    sections: new FormControl(),
    setAsDefault: new FormControl(true)
  });

  private shouldEnableCommonSettingsOnSelect: Partial<Record<ReportSection, boolean>> = {};

  abstract get settingsKey(): string;
  abstract get createReportAction(): Action;
  abstract getSections(filteredSections: S): SectionSelectorListItem[];

  protected constructor(protected store: Store, private drawerService: FireflyDrawerService) {
    super();
    this.drawerService.setForm(this.form);
    this.drawerService.changeDrawerFooterVisibility(false);
  }

  override onInjectInputs(inputs: { request?: T; filteredSections: S }) {
    this.request = inputs.request;
    const [mainSections, commonForAllSectionsSettings] = partition(
      this.getSections(inputs.filteredSections),
      section => !section.isCommonForAllSections
    );
    this.commonForAllSectionsSettings = commonForAllSectionsSettings;
    this.form.controls.sections.setValue(mainSections);

    if (!commonForAllSectionsSettings.length) return;
    commonForAllSectionsSettings.forEach(setting => {
      this.form.addControl(setting.id, new FormControl(setting.isSelected));
    });
  }

  settingsChange(sections: SectionSelectorListItem[]) {
    this.disableSaving = !sections.filter(s => s.isSelected).length;
    this.switchOffSetAsDefault();

    if (!this.commonForAllSectionsSettings.length) return;

    if (this.disableSaving) {
      this.disableCommonSectionSettings();
    } else {
      this.enableSettingsForPreviouslySelected();
    }
  }

  switchOffSetAsDefault() {
    this.form.controls.setAsDefault.setValue(false);
  }

  private disableCommonSectionSettings() {
    this.commonForAllSectionsSettings.forEach(setting => {
      if (this.form.controls[setting.id].value) {
        this.shouldEnableCommonSettingsOnSelect[setting.id] = true;
      }
      this.form.controls[setting.id].setValue(false);
    });
  }

  private enableSettingsForPreviouslySelected() {
    this.commonForAllSectionsSettings.forEach(setting => {
      if (!this.shouldEnableCommonSettingsOnSelect[setting.id]) return;

      this.form.controls[setting.id].setValue(true);
      this.shouldEnableCommonSettingsOnSelect[setting.id] = false;
    });
  }

  saveSettings() {
    const mainSections = this.form.controls.sections.value as SectionSelectorListItem[];
    this.store.dispatch(
      saveSectionsSettings({
        sections: [...mainSections, ...this.getCommonForAllSectionsSettings],
        key: this.settingsKey
      })
    );
  }

  createReport() {
    if (this.form.controls.setAsDefault.touched && this.form.controls.setAsDefault.value) {
      this.saveSettings();
    }

    this.store.dispatch(this.createReportAction);

    this.form.markAsPristine();
    this.close({ reportInProgress: true });
  }

  get getSelectedSection() {
    return (this.form.controls.sections.value as SectionSelectorListItem[]).filter(s => s.isSelected).map(s => s.id);
  }

  get setAsDefaultControl() {
    return this.form.controls['setAsDefault'] as FormControl;
  }

  get getCommonForAllSectionsSettings(): SectionSelectorListItem[] {
    if (!this.commonForAllSectionsSettings.length) return [];

    this.commonForAllSectionsSettings.forEach(section => (section.isSelected = this.form.controls[section.id].value));
    return this.commonForAllSectionsSettings;
  }

  cancel() {
    this.close();
  }
}
