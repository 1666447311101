<svg:g
  #container
  *ngFor="let group of barGroups; let i = index; trackBy: trackBy"
  class="f-bar-series-container"
  [class.selected]="selectedBarIndex === i"
  [class.interactive]="interactions.hover"
  [attr.data-automation-id]="'bar-group-' + i"
  (click)="onClick(container, group.x, i)"
  (mouseenter)="onMouseEnter($any(popover), group.x, i)"
  (mouseleave)="closePopover($any(popover))"
>
  <rect
    class="f-bar-halo"
    [class.hidden]="!interactions.hover"
    [attr.height]="dimensions.height"
    [attr.width]="group.halo?.width"
    [attr.x]="group.halo?.x"
    [attr.y]="0"
  ></rect>
  <svg:g
    f-positive-negative-bar-series
    [seriesId]="i"
    [yScale]="yScale"
    [xScale]="x1Scale"
    [series]="$any(group.series)"
    [animation]="animation"
    [animationDelay]="animationDelay"
    [animationDuration]="animationDuration"
    [attr.transform]="group.transform"
    [parentChartComponent]="parentChartComponent"
    [interactions]="{ hover: false, click: false }"
    [dimensions]="{ height: dimensions.height, width: xScale.bandwidth() }"
    [ngbPopover]="popoverTemplate && popoverTemplateOutlet"
    [placement]="popoverPlacement"
    [popoverClass]="popoverClass"
    [triggers]="'manual'"
    [container]="'body'"
    #popover="ngbPopover"
  ></svg:g>
  <ng-template #popoverTemplateOutlet>
    <ng-container
      *ngTemplateOutlet="$any(popoverTemplate); context: { $implicit: popoverContextData(i) }"
    ></ng-container>
  </ng-template>
</svg:g>
