import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, of } from 'rxjs';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { LocalizationService } from '@capital-access/common/localization';
import { Breakpoint, ChipSize } from '@capital-access/firefly/components';
import { SearchCategory, SpecialActions } from '../../+state/models';
import { filterChanged, reset, toggleFilterPanel } from '../../+state/search.actions';
import * as searchSelectors from '../../+state/search.selectors';
import { startSpecialAction } from '../../+state/selected-entities.actions';
import { SEARCH_LOCALIZATION_SCOPE } from '../../constants';

@Component({
  selector: 'ca-one-search-results',
  templateUrl: './one-search-results.component.html',
  styleUrls: ['./one-search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OneSearchResultsComponent implements OnInit {
  @ViewChild('mobileResultContainer') mobileResultContainer?: ElementRef;

  constructor(private store: Store, private localizationService: LocalizationService) {}

  SearchCategory = SearchCategory;
  ChipSize = ChipSize;

  items$: Observable<Record<string, string | null>[]> = of([]);
  hasSelectedEntities$ = this.store.select(searchSelectors.hasSelectedEntities);
  hasAnyResults$ = this.store.select(searchSelectors.hasAnyResults);
  isSearchFailed$ = this.store.select(searchSelectors.isSearchFailed);
  isFiltersButtonActive$ = this.store.select(searchSelectors.showFilters);
  showPanel$ = this.store.select(searchSelectors.showPanel);
  hasFilteredResults$ = this.store.select(searchSelectors.hasFilteredResults);
  isAnyCategoryFailed$ = this.store.select(searchSelectors.isAnyCategoryFailed);
  getFilter$ = this.store.select(searchSelectors.getFilter);

  popupHeaderState$ = this.store.select(searchSelectors.getPopupHeaderState);

  availableSpecialActions$ = combineLatest([
    this.store.select(searchSelectors.getAvailableSpecialActions),
    this.store.select(getFeatureToggle('one-search-events-add-activity'))
  ]).pipe(
    map(([specialActions, isAddActivityEnabled]) =>
      specialActions.filter(action => action.action !== SpecialActions.AddActivity || isAddActivityEnabled)
    )
  );

  get mobile(): boolean {
    return window.innerWidth < Breakpoint.Sm;
  }

  ngOnInit() {
    this.items$ = this.localizationService
      .localize(['all', 'institutions', 'contacts', 'funds', 'events'], {}, SEARCH_LOCALIZATION_SCOPE)
      .pipe(
        map(([all, institutions, contacts, funds, events]) => [
          { id: null, text: all },
          { id: SearchCategory.Institutions, text: institutions },
          { id: SearchCategory.Contacts, text: contacts },
          { id: SearchCategory.Funds, text: funds },
          { id: SearchCategory.Events, text: events }
        ])
      );
  }

  onSelect(item: unknown) {
    this.onFilterChanged((item as Record<string, SearchCategory | null>).id);

    if (this.mobileResultContainer) {
      this.mobileResultContainer.nativeElement.scrollTop = 0;
    }
  }

  onStartSpecialAction(action: SpecialActions) {
    this.store.dispatch(startSpecialAction({ action }));
  }

  onFilterChanged(filter: SearchCategory | null) {
    this.store.dispatch(filterChanged({ filter }));
  }

  toggleFilterPanel() {
    this.store.dispatch(toggleFilterPanel());
  }

  onClickOutside() {
    this.store.dispatch(reset());
  }
}
