import { Injectable } from '@angular/core';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { NavConfig, NavConfigFn, NavigationConfigProvider } from '@capital-access/common/navigation';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { REPORTING_FEATURE_TOGGLE, REPORTING_PATH } from './constants';

@Injectable()
export class ReportingNavConfigProvider implements NavigationConfigProvider {
  constructor(private store: Store) {}

  resolveNavConfig: NavConfigFn = () => {
    return this.store.select(getFeatureToggle(REPORTING_FEATURE_TOGGLE)).pipe(
      map(isFeatureToggleEnabled => {
        return <NavConfig>{
          top: [
            {
              localizationKey: 'reporting',
              icon: 'f-i-report',
              order: 700,
              link: REPORTING_PATH,
              enabled: isFeatureToggleEnabled
            }
          ]
        };
      })
    );
  };
}
