<f-chart
  [view]="view"
  [xScale]="xScale"
  [yScale]="yScale"
  [animation]="animation"
  [integerYAxis]="integerYAxis"
  [fitContainer]="fitContainer"
  [truncateXAxisTicks]="truncateXAxisTicks"
  [truncateYAxisTicks]="truncateYAxisTicks"
  [rotateXAxisTicks]="rotateXAxisTicks"
  [maxXAxisTickLength]="maxXAxisTickLength"
  [maxYAxisTickLength]="maxYAxisTickLength"
  [maxYAxisTicksCount]="maxYAxisTicksCount"
  [xAxisTickFormatting]="xAxisTickFormatting"
  [yAxisTickFormatting]="yAxisTickFormatting"
  [showXAxisGridlines]="showXAxisGridlines"
  [showYAxisGridlines]="showYAxisGridlines"
  [showXAxisTicksLabels]="showXAxisTicksLabels"
  [showYAxisTicksLabels]="showYAxisTicksLabels"
  [showLineAxisTicksLabels]="showLineAxisTicksLabels"
  [xAxisTickLabelClasses]="xAxisTickLabelClasses"
  [xAxisTickLabelOffset]="xAxisTickLabelOffset"
  [showXAxisDomain]="showXAxisDomain"
  [showYAxisDomain]="showYAxisDomain"
  [popoverTemplate]="popoverTemplate"
  [popoverPlacement]="popoverPlacement"
  [legendOptions]="legendOptions"
  [showLegend]="showLegend"
  [legendRootClass]="legendRootClass"
  [leftAxisLabelText]="leftAxisLabelText"
  [labelsOffset]="labelsOffset"
  [showBorders]="showBorders"
  [showLabels]="showLabels"
  [condensedOnDesktop]="condensedOnDesktop"
  [condensedAxisOnMobile]="condensedAxisOnMobile"
  [chartPatternsTemplate]="chartPatternsTemplate"
  (dimensionsChanged)="onDimensionsChanged($event)"
>
  <svg:g
    *ngIf="dimensions"
    f-positive-negative-grouped-bar-series
    [series]="data"
    [xScale]="xScale"
    [yScale]="yScale"
    [animation]="animation"
    [dimensions]="dimensions"
    [barClasses]="barClasses"
    [negativeBarClasses]="negativeBarClasses"
    [groupPadding]="groupPadding"
    [interactions]="interactions"
    [animationDelay]="animationDelay"
    [condensedOnDesktop]="condensedOnDesktop"
    [parentChartComponent]="chartComponent"
    [animationDuration]="animationDuration"
    [popoverPlacement]="popoverPlacement"
    [popoverOpenDelay]="popoverOpenDelay"
    [popoverCssClass]="popoverCssClass"
    [popoverTemplate]="popoverTemplate"
    [activeBarIndex]="activeBarIndex"
    (seriesClick)="onGroupClick($event)"
  />
</f-chart>
