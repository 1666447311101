import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import isNull from 'lodash/isNull';
import { ChartDataEntry, ChartDataSeries } from '../common';
import { FireflyGroupedBarChartComponent } from '../grouped-bar-chart';

@Component({
  selector: 'f-positive-negative-grouped-bar-chart',
  templateUrl: './positive-negative-grouped-bar-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyPositiveNegativeGroupedBarChartComponent extends FireflyGroupedBarChartComponent {
  @Input() negativeBarClasses: string[] = [];

  chartComponent = FireflyPositiveNegativeGroupedBarChartComponent;

  getYDomain(data: ChartDataSeries[]): number[] {
    const dataEntry = data as { name: string; value: number; series: ChartDataSeries[] }[];

    const values = dataEntry
      .map(d => {
        return this.getMinAndMaxValues(
          (d as ChartDataSeries).series
            .map(s => {
              return (s as ChartDataSeries).series.map(i => (i as ChartDataEntry).value!).filter(i => !isNull(i));
            })
            .flat()
        );
      })
      .flat();

    return this.getMinAndMaxValues(values);
  }

  private getMinAndMaxValues(values: number[]) {
    const min = Math.min(0, ...values);
    const max = Math.max(0, ...values);
    return [min, max];
  }
}
