<ng-container [caLocalizationScope]="localizationScope">
  @if (csAdvisorData && isAdvisorDataLoaded) {
  <div
    *ngIf="csAdvisorData.phoneNumber || csAdvisorData.email"
    class="border-bottom text-break pb-4 mb-4"
    data-automation-id="client-service-info"
  >
    <h4 class="heading-20 mb-3">{{ 'clientEngagementAdvisorTitle' | caLocalize }}</h4>
    <div *ngIf="csAdvisorData.firstName || csAdvisorData.lastName" class="fw-bold mb-1">
      {{ csAdvisorData.firstName }} {{ csAdvisorData.lastName }}
    </div>
    <div *ngIf="csAdvisorData.phoneNumber" class="mb-1">
      <a class="link link-main" href="tel:{{ csAdvisorData.phoneNumber }}">{{ csAdvisorData.phoneNumber }}</a>
    </div>
    <div *ngIf="csAdvisorData.email">
      <a class="link link-primary" href="mailto:{{ csAdvisorData.email }}">{{ csAdvisorData.email }}</a>
    </div>
  </div>
  } @else {
  <div *ngIf="isAdvisorDataLoadFailed" data-automation-id="client-service-error" class="border-bottom mb-4">
    <h4 class="heading-20">{{ 'clientEngagementAdvisorTitle' | caLocalize }}</h4>
    <f-inner-error
      class="d-block w-fit-content pb-4 pt-3"
      [info]="'tryAgainTitle' | caLocalize"
      [isFlexiblePlacement]="true"
      [isHorizontal]="true"
    ></f-inner-error>
  </div>
  }
</ng-container>
