import { Injectable } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { TranslocoScope } from '@jsverse/transloco';
import { isString } from '@capital-access/common/utils';
@Injectable({
  providedIn: 'root'
})
export class LocalizationScopesLoadQueue {
  private scopes: string[] = ['']; // empty string as initial value to load root `capital-access` scope
  private loadedScopes: string[] = [];

  /**
   * Queues scopes for loading if it's not loaded yet
   * @param providerScope localization scope to load
   */
  public queue(providerScope: TranslocoScope | TranslocoScope[]) {
    if (!Array.isArray(providerScope)) {
      providerScope = [providerScope];
    }
    this.scopes.push(
      ...(providerScope.map(s => this.resolveScope(s)).filter(s => s && !this.loadedScopes.includes(s)) as string[])
    );
  }

  public get empty() {
    return this.scopes.length === 0;
  }

  /**
   * Get all queued scopes and clear the queue
   */
  public getAll() {
    const notLoadedScopes = [...this.scopes];
    this.scopes = [];
    this.loadedScopes.push(...notLoadedScopes);
    return notLoadedScopes;
  }

  private resolveScope(scope: TranslocoScope): string | null {
    if (!scope) {
      return null;
    }
    if (isString(scope)) {
      return scope;
    }
    return scope.scope;
  }
}
