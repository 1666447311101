export enum ReportSection {
  InstitutionDetails = 'InstitutionDetails',
  InstitutionInvestmentApproach = 'InstitutionInvestmentApproach',
  FinancialHighlights = 'FinancialHighlights',
  EquityPortfolio = 'EquityPortfolio',
  HistoricalOwnership = 'HistoricalOwnership',
  ActivityHistory = 'ActivityHistory',
  InstitutionBaseInfo = 'InstitutionBaseInfo',
  ContactBaseInfo = 'ContactBaseInfo',
  ContactBiography = 'ContactBiography',
  SustainabilityFocus = 'SustainabilityFocus',
  Targeting = 'Targeting',
  PeerOwnership = 'PeerOwnership',
  SuitabilityFactors = 'SuitabilityFactors',
  PrimaryContactBiography = 'PrimaryContactBiography',
  CustomFields = 'CustomFields',
  FundSummary = 'FundSummary',
  OwnershipSummary = 'OwnershipSummary',
  Commentary = 'Commentary',

  TradingAnalysisAll = 'TradingAnalysisAll',
  TradingAnalysisBuys = 'TradingAnalysisBuys',
  TradingAnalysisSells = 'TradingAnalysisSells',

  PredictedMovements = 'PredictedMovements',
  StyleName = 'StyleName',
  HolderRegionName = 'HolderRegionName',
  HolderInvestmentCenterName = 'HolderInvestmentCenterName',
  HolderCountryName = 'HolderCountryName',
  SustainabilityFitMetric = 'SustainabilityFitMetric',
  ParticipantBiography = 'ParticipantBiography',
  Orientation = 'Orientation',
  Turnover = 'Turnover',
  CombinedOwnership = 'CombinedOwnership'
}
