import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalContent } from '../../../modal';

@Component({
  templateUrl: './mobile-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyMultiChoiceChipGroupMobileComponent implements ModalContent, OnInit {
  context!: {
    chipItems: Record<string, unknown>[] | null;
    valuePath: string;
    selectedIds: unknown[];
    idField: string;
    onToggle: (i: unknown) => void;
  };

  selectedIds: unknown[] = [];

  ngOnInit() {
    this.selectedIds = [...this.context.selectedIds];
  }

  isSelected(id: unknown) {
    return this.selectedIds.includes(id);
  }

  onToggle(item: Record<string, unknown>) {
    this.toggleSelection(item);
  }

  updateListOnActionButtonClick(isSelected: boolean) {
    if (!this.context.chipItems) return;

    this.context.chipItems.forEach(item => {
      if (this.isSelected(item[this.context.idField]) !== isSelected) this.toggleSelection(item);
    });
  }

  private toggleSelection(item: Record<string, unknown>) {
    const id = item[this.context.idField];
    this.selectedIds.includes(id)
      ? this.selectedIds.splice(this.selectedIds.indexOf(id), 1)
      : this.selectedIds.push(id);

    this.context.onToggle(item);
  }
}
