import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FireflyGroupedBarSeriesComponent } from '../grouped-bar-series';
import { ChartDataEntry, ChartDataSeries } from '../models/common-chart-models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[f-positive-negative-grouped-bar-series]',
  templateUrl: 'positive-negative-grouped-bar-series.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyPositiveNegativeGroupedBarSeriesComponent extends FireflyGroupedBarSeriesComponent {
  @Input() negativeBarClasses: string[] = [];

  override getGroups(): ChartDataSeries[] {
    const step = this.xScale.step();
    const width = this.xScale.bandwidth();

    this.padding = (step - width) / 2;

    return this.series.map(group => {
      const x = this.xScale(group.name)! || 0;
      return {
        ...group,
        series: group.series.map((s, i) => {
          const series = (s as ChartDataSeries).series.map(item => {
            return {
              ...(item as ChartDataEntry),
              cssClass: this.getBarClasses(i, item as ChartDataEntry)
            };
          });
          return { ...s, series };
        }),
        halo: { width: step, x: x - this.padding },
        transform: `translate(${x}, 0)`,
        x
      };
    });
  }

  getModalContext(activeBarIndex: number, sliceStartIndex: number, sliceEndIndex: number) {
    const context = super.getModalContext(activeBarIndex, sliceStartIndex, sliceEndIndex);
    return {
      ...context,
      negativeBarClasses: this.negativeBarClasses
    };
  }

  private getBarClasses(index: number, entry: ChartDataEntry): string {
    const entryClass = entry.cssClass ?? '';
    if (entry.value !== null && entry.value < 0 && this.negativeBarClasses?.length > index) {
      return `${this.negativeBarClasses[index]} ${entryClass}`;
    }
    return `${this.barClasses[index]} ${entryClass}`;
  }
}
