import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BaseSmallCardRowComponent } from './base-small-card-row/base-small-card-row.component';

@Component({
  selector: 'f-small-card-row',
  templateUrl: './small-card-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmallCardRowComponent extends BaseSmallCardRowComponent implements AfterViewInit {
  @Input() firstContainerTwoLines = false;
  @Input() reducedRowSpacing = false;
  @Input() last = false;
  @Input() firstContainerID: string | null = null;
  @Input() hasAlwaysContent = false;
  @Input() excludeLastFromSpacing = false;

  @ContentChild('fFirstContainer', { read: TemplateRef }) firstContainerContent!: TemplateRef<unknown>;
  @ContentChild('fSecondContainer', { read: TemplateRef }) secondContainerContent!: TemplateRef<unknown>;
  @ContentChild('fThirdContainer', { read: TemplateRef }) thirdContainerContent!: TemplateRef<unknown>;
  @ContentChild('fFourthContainer', { read: TemplateRef }) fourthContainerContent!: TemplateRef<unknown>;

  @ViewChild('valueSecondContainerContent') valueSecondContainerContent?: ElementRef;
  @ViewChild('valueFourthContainerContent') valueFourthContainerContent?: ElementRef;

  showSecondContainerContent = true;
  showFourthContainerContent = true;

  get bottomRowFirstOrSecondTitle(): boolean {
    return !!(this.firstContainerWithBadge && this.firstContainerTitle) || !!this.secondContainerTitle;
  }

  get mobileBottomRow(): boolean {
    return this.mobile && (this.bottomRowFirstOrSecondTitle || !!this.fourthContainerTitle);
  }

  ngAfterViewInit(): void {
    if (!this.hasAlwaysContent) {
      this.updateValueElement();
    }
  }

  private hasContentCheck(valueContent: ElementRef | undefined): boolean {
    return !!(
      valueContent?.nativeElement.innerText?.trim().length || valueContent?.nativeElement.querySelector('.f-i')
    );
  }

  private updateValueElement(): void {
    this.showSecondContainerContent = this.hasContentCheck(this.valueSecondContainerContent);
    this.showFourthContainerContent = this.hasContentCheck(this.valueFourthContainerContent);
    this.cdr.detectChanges();
  }
}
