import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { FireflyInnerErrorModule } from '@capital-access/firefly/components';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../common-components.const';
import { ContactSupportAdvisorComponent } from './contact-support-advisor.component';

@NgModule({
  imports: [
    CommonModule,
    CommonLocalizationModule.forFeature(COMMON_COMPONENTS_LOCALIZATION_SCOPE),
    FireflyInnerErrorModule
  ],
  declarations: [ContactSupportAdvisorComponent],
  exports: [ContactSupportAdvisorComponent]
})
export class CommonContactSupportAdvisorModule {}
