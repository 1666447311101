import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  createProfileReport,
  createProfileReportFinished,
  createProfileReportStarted
} from '@capital-access/common/actions';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import {
  getUserSettingsCulture,
  getUserSettingsCurrency,
  getUserSettingsLanguage,
  getUserSettingsTimezone
} from '@capital-access/common/globalization';
import { LocalizationService } from '@capital-access/common/localization';
import {
  getSelectedRelatedSecurities,
  getSelectedSecurity,
  getSelectedSecuritySurveillancePermission
} from '@capital-access/common/security';
import { NotificationService } from '@capital-access/firefly/components';
import { getDefaultSecurityListSettings } from '@capital-access/lists/common';
import { ProfileType } from '@capital-access/profiles/common';
import { MAX_REPORT_PROFILES_COUNT, MAX_SMALL_REPORT_ITEMS_COUNT } from '@capital-access/reporting/common';
import { LOCALIZATION_KEY } from '../../constants';
import { generateReport } from '../../core/+state/report.actions';
import { OwnershipSource } from '../../core/models/ownership-source.enum';
import { ReportSection } from '../../core/models/report-sections';
import { getSavedLayout, getSavedSections } from '../../core/utils/section-settings.utils';
import * as reportActions from '../+state/profile-report.actions';
import { CreateProfileReportDto, CreateProfileReportRequest } from '../models/profile-report.models';
import {
  DEFAULT_LAYOUT,
  getConditionalReportSections,
  getDefaultSections,
  ProfileReportLayout
} from '../models/profile-report-sections';
import { ProfileReportNamingService } from '../services/profile-report-naming.service';

@Injectable()
export class ProfileReportEffects {
  launchProfileReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createProfileReport),
      withLatestFrom(
        this.store.select(getSelectedRelatedSecurities),
        this.store.select(getFeatureToggle('profile-report-historical-ownership-SKYL-2377')),
        this.store.select(getFeatureToggle('profile-report-sustainability-fit-ISSYN-2489')),
        this.store.select(getFeatureToggle('reporting-primary-contact-biography-section-cos-6429')),
        this.store.select(getFeatureToggle('combined-ownership-cora-3277')),
        this.store.select(getFeatureToggle('one-pager-report-cos-6493'))
      ),
      map(
        ([
          action,
          relatedSecurities,
          isHistoricalOwnershipToggleEnabled,
          isSustainabilityFitToggleEnabled,
          isPrimaryContactBiographyToggleEnabled,
          isCombinedOwnershipToggleEnabled,
          onePagerEnabled
        ]) => {
          const conditionalSections = getConditionalReportSections({
            historicalOwnership: action.profileType !== ProfileType.Contact || isHistoricalOwnershipToggleEnabled,
            sustainabilityFitMetric: action.profileType === ProfileType.Institution && isSustainabilityFitToggleEnabled,
            primaryContactBiography:
              action.profileType === ProfileType.Institution && isPrimaryContactBiographyToggleEnabled,
            participantBiography: false,
            combinedOwnership:
              isCombinedOwnershipToggleEnabled &&
              action.profileType === ProfileType.Institution &&
              relatedSecurities?.length >= 2
          });

          const layout = onePagerEnabled ? getSavedLayout(action.profileType, DEFAULT_LAYOUT) : undefined;
          const defaultSection = getDefaultSections(action.profileType as ProfileType, layout);

          let sections: ReportSection[];

          if (!action.useCustomSettings) {
            sections = [];
          } else {
            const key =
              layout === ProfileReportLayout.OnePager
                ? `${ProfileReportLayout.OnePager}_${action.profileType}`
                : action.profileType;

            sections = getSavedSections(key, defaultSection, conditionalSections)
              .filter(s => s.isSelected)
              .map(s => s.id);
          }

          return reportActions.createProfileReport({
            request: CreateProfileReportDto.create({
              profiles: action.profiles,
              profileType: action.profileType,
              combinedSecurityIds: action.combinedSecurityIds || relatedSecurities.map(x => x.id)
            }),
            sections,
            layout
          });
        }
      )
    )
  );

  createProfileReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportActions.createProfileReport),
      withLatestFrom(
        this.store.select(getSelectedSecurity),
        this.store.select(getUserSettingsTimezone),
        this.store.select(getFeatureToggle('skyl-2231-culture-specific-formatting')),
        this.store.select(getUserSettingsCulture),
        this.store.select(getUserSettingsLanguage),
        this.store.select(getDefaultSecurityListSettings),
        this.store.select(getSelectedSecuritySurveillancePermission),
        this.store.select(getFeatureToggle('surveillance-across-application-cora-2613')),
        this.store.select(getUserSettingsCurrency)
      ),
      mergeMap(
        ([
          { request, sections, layout },
          security,
          timeZone,
          cultureFtEnabled,
          culture,
          language,
          defaultPeerList,
          isSrvPermissioned,
          isSrvFtEnabled,
          currency
        ]) => {
          if (
            !!request.profiles?.length &&
            request.profiles.length > 0 &&
            request.profiles.length <= MAX_REPORT_PROFILES_COUNT
          ) {
            const profileIds = request.profiles.map(p => p.id.toString());
            const isSrv = security?.surveillance ?? false;
            const reportRequest = this.profileReportNameService
              .getProfileReportFileName(isSrvPermissioned && isSrvFtEnabled, isSrv, request, timeZone, layout)
              .pipe(
                map(filename => {
                  return this.generateProfileReportRequest(
                    {
                      profileIds,
                      profileType: request.profileType,
                      includedContacts: request.includedContacts,
                      timeZone,
                      culture: cultureFtEnabled ? culture : 'en-US',
                      language: language,
                      securityId: security?.id,
                      combinedSecurityIds: request.combinedSecurityIds,
                      sections,
                      defaultPeerListId: defaultPeerList.id!,
                      fileName: filename,
                      currency,
                      dataSource: isSrv ? OwnershipSource.Surveillance : OwnershipSource.Public
                    },
                    layout
                  );
                })
              );

            if (request.profiles.length > MAX_SMALL_REPORT_ITEMS_COUNT) {
              return this.localization.localize('waitingTimeMessage', {}, { scope: LOCALIZATION_KEY }).pipe(
                tap(message => this.notificationService.notify(message, { type: 'info' })),
                switchMap(() => reportRequest)
              );
            }

            return reportRequest;
          }

          return of(createProfileReportFinished());
        }
      )
    )
  );

  createProfileReportStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createProfileReport, reportActions.createProfileReport),
      map(() => createProfileReportStarted())
    )
  );

  private generateProfileReportRequest(request: CreateProfileReportRequest, layout?: ProfileReportLayout) {
    const successMessage = `${request.profileType}Profile${request.profileIds.length > 1 ? 's' : ''}Created`;
    const failureMessage = `${request.profileType}InvestorProfile${request.profileIds.length > 1 ? 's' : ''}Failed`;

    return generateReport({
      request,
      url:
        layout === ProfileReportLayout.OnePager
          ? 'one-pager-reports/process/batch/launch'
          : 'investor-profile-reports/process/batch/launch',
      successMessage,
      failureMessage,
      finishAction: createProfileReportFinished()
    });
  }

  constructor(
    private actions$: Actions,
    private store: Store,
    private localization: LocalizationService,
    private notificationService: NotificationService,
    private profileReportNameService: ProfileReportNamingService
  ) {}
}
