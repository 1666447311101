import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { addActivityWithPayload, addToActivity } from '@capital-access/common/actions';
import { FireflyDrawerService } from '@capital-access/firefly/components';
import { addProfilesToExistingList, ListType } from '@capital-access/lists/common';
import { SearchService } from '../services/search.service';
import { SearchDrawerService } from '../services/search-drawer.service';
import { Contact, Event, Institution, SearchCategory, SpecialActions } from './models';
import * as SearchActions from './search.actions';
import { close } from './search.actions';
import { State } from './search.reducer';
import {
  getFilter,
  getSearchCriteria,
  getSearchResults,
  getSelectedEntities,
  hasSelectedEntities
} from './search.selectors';
import { startSpecialAction } from './selected-entities.actions';
import { getBuckets, getSearchCount } from './utils';

@Injectable()
export class SearchEffects {
  private readonly drawerActions = [SpecialActions.AddToTargetView];
  private customDrawerZIndex = 1055;

  close$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.criteriaChanged),
      withLatestFrom(this.store.select(hasSelectedEntities)),
      filter(([{ criteria }, hasSelected]) => this.isClosingRequired(criteria, hasSelected)),
      map(() => SearchActions.close())
    )
  );

  reset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.unselectEntity),
      withLatestFrom(this.store.select(hasSelectedEntities), this.store.select(getSearchCriteria)),
      filter(([, hasSelected, criteria]) => this.isClosingRequired(criteria, hasSelected)),
      map(() => SearchActions.reset())
    )
  );

  criteriaChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.criteriaChanged),
      filter(({ criteria }) => !!criteria && criteria.length > 2),
      map(a => SearchActions.searchStarted({ criteria: a.criteria }))
    )
  );

  startSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.searchStarted),
      switchMap(action => {
        this.drawerService.setZIndex(this.customDrawerZIndex);
        return this.searchService.search(action.criteria, getSearchCount()).pipe(
          map(results =>
            SearchActions.loadSearchResultsSuccess({
              results
            })
          ),
          takeUntil(this.actions$.pipe(ofType(SearchActions.close))),
          catchError(error => of(SearchActions.loadSearchResultsFailure(error)))
        );
      })
    )
  );

  viewBucketsChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.loadSearchResultsSuccess, SearchActions.filterChanged),
      withLatestFrom(this.store.select(getSearchResults), this.store.select(getFilter)),
      map(([_, results, filter]) => {
        return SearchActions.viewBucketsChanged({ buckets: getBuckets(results, filter) });
      })
    )
  );

  resetZIndexOnClose$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(close),
        tap(() => this.drawerService.resetZIndex())
      ),
    { dispatch: false }
  );

  startSpecialActionsInDrawer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startSpecialAction),
        filter(action => this.drawerActions.includes(action.action)),
        tap(() => this.searchDrawerService.showDrawer(''))
      ),
    { dispatch: false }
  );

  addToList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startSpecialAction),
      filter(action => action.action === SpecialActions.AddToList),
      withLatestFrom(this.store.select(getSelectedEntities)),
      map(([, selectedEntities]) =>
        addProfilesToExistingList({
          ids: (selectedEntities as Array<Contact | Institution>).map(entity => ({
            crmId: entity.crmId,
            prId: entity.prospectingId
          })),
          listType: selectedEntities[0].category === SearchCategory.Contacts ? ListType.Contact : ListType.Institution
        })
      )
    )
  );

  addEventActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startSpecialAction),
      filter(action => action.action === SpecialActions.AddActivity),
      withLatestFrom(this.store.select(getSelectedEntities)),
      map(([, selectedEntities]) => selectedEntities[0] as Event),
      filter(selected => !!selected),
      map((selected: Event) => addActivityWithPayload({ eventId: selected.id }))
    )
  );

  addToActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startSpecialAction),
      filter(action => action.action === SpecialActions.AddToNewActivity),
      withLatestFrom(this.store.select(getSelectedEntities)),
      filter(([, selected]) => !!selected),
      map(([, selected]) => {
        const contacts = selected
          .filter(s => s.category === SearchCategory.Contacts)
          .map(s => {
            const contact = s as Contact;
            return { prId: contact.prospectingId, crmId: contact.crmId };
          });
        const institutions = selected
          .filter(s => s.category === SearchCategory.Institutions)
          .map(s => {
            const contact = s as Institution;
            return { prId: contact.prospectingId, crmId: contact.crmId };
          });
        return addToActivity({ contacts, institutions });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private searchService: SearchService,
    private searchDrawerService: SearchDrawerService,
    private drawerService: FireflyDrawerService,
    private store: Store<State>
  ) {}

  private isClosingRequired(criteria: string, hasSelectedItems: boolean) {
    return (!criteria || criteria.length <= 2) && !hasSelectedItems;
  }
}
