<ng-container *ngIf="responsive; else chips">
  <div class="d-none d-lg-flex">
    <ng-container *ngTemplateOutlet="chips"></ng-container>
  </div>

  <button
    class="btn btn-secondary d-flex align-items-center d-sm-none"
    (click)="openModal()"
    data-automation-id="open-mobile-modal-button"
  >
    {{ selectedIds.length ? (selectedItemsCount$ | async)! : (noItemsSelected$ | async)! }}
    <i class="f-i f-i-xs f-i-caret-down ms-1"></i>
  </button>

  <f-dropdown
    class="d-none d-sm-flex d-lg-none"
    [btnStyle]="'btn-secondary'"
    [autoClose]="'outside'"
    [placement]="dropdownPlacement"
    [text]="selectedIds.length ? (selectedItemsCount$ | async)! : (noItemsSelected$ | async)!"
  >
    <f-list class="pt-0">
      <f-action-buttons
        *ngIf="chipItems !== null"
        class="border-bottom"
        [showSelectAll]="chipItems.length !== selectedIds.length"
        [showClearAll]="!!selectedIds.length"
        (updateList)="updateListOnActionButtonClick($event)"
      >
      </f-action-buttons>
      <f-list-item-checkbox
        fDropdownItem
        *ngFor="let item of chipItems"
        (click)="onToggle(item)"
        [checked]="isSelected(item[idField])"
      >
        {{ item | fNestedValue : valuePath }}
      </f-list-item-checkbox>
    </f-list>
  </f-dropdown>
</ng-container>

<ng-template #chips>
  <f-chip
    *ngFor="let item of chipItems; let lastItem = last; let i = index"
    class="mt-2"
    [class.me-1]="!lastItem"
    [class.me-3]="item[groupedField] !== chipItems![i + 1]?.[groupedField] && !lastItem"
    [type]="isSelected(item[idField]) ? ChipType.MultiChoice : ChipType.Enabled"
    [size]="size"
    (chipClick)="onToggle(item)"
    [attr.data-automation-id]="'multi-choice-chip-' + item[idField]"
  >
    {{ item | fNestedValue : valuePath }}
  </f-chip>
</ng-template>
