import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import { fromEvent, of, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FireflyLocalizationService } from '../../utils';
import { FireflyTopNavService } from './services/top-nav.service';

@Component({
  selector: 'f-top-nav',
  templateUrl: './top-nav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopNavComponent implements OnInit, OnDestroy {
  @Input() flexibleTopNav = false;
  searchAnimationState: 'on' | 'off' = 'off';
  searchAnimationDuration = 250;
  mobileSearchIsActive = false;
  searchContainerOffset = 0;
  cancelSearchBtnTitle$ = of('Cancel');

  @ViewChild('toggleContainer', { static: true }) toggleContainer?: ElementRef;
  @ViewChild('searchContainer', { static: true }) searchContainer!: ElementRef;
  @ViewChild('navigationContainer', { static: true }) navigationContainer!: ElementRef;

  private destroyed$ = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private topNavService: FireflyTopNavService,
    @Optional() private localizationService: FireflyLocalizationService
  ) {}

  get searchContainerStyles() {
    return this.mobileSearchIsActive ? { transform: `translate3d(-${this.searchContainerOffset}px, 0, 0)` } : {};
  }

  ngOnInit() {
    if (this.localizationService) {
      this.cancelSearchBtnTitle$ = this.localizationService.localize('cancelSearchBtn', {});
    }

    fromEvent(this.searchContainer.nativeElement, 'transitionend')
      .pipe(takeUntil(this.destroyed$), debounceTime(this.searchAnimationDuration))
      .subscribe(() => {
        this.searchAnimationState = 'off';
        this.cdr.detectChanges();
      });

    this.topNavService.searchOpenChange$.pipe(takeUntil(this.destroyed$)).subscribe(open => {
      open ? this.openMobileSearch() : this.closeMobileSearch();
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private openMobileSearch() {
    if (this.mobileSearchIsActive) return;

    const navigationContainerWidth = this.navigationContainer.nativeElement.getBoundingClientRect().width;
    const navigationContainerMargin = window.getComputedStyle(this.navigationContainer.nativeElement).marginRight;
    this.searchContainerOffset = navigationContainerWidth + parseInt(navigationContainerMargin);

    window.requestAnimationFrame(() => {
      this.searchAnimationState = 'on';
      this.mobileSearchIsActive = true;
      this.cdr.detectChanges();
    });
  }

  closeMobileSearch() {
    if (!this.mobileSearchIsActive) return;
    this.searchAnimationState = 'on';
    this.mobileSearchIsActive = false;
    this.cdr.detectChanges();
    (document.activeElement as HTMLElement)?.blur();
  }
}
