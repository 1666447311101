<div
  class="navbar"
  [class.navbar-flexible]="flexibleTopNav"
  [class.mobile-search-active]="mobileSearchIsActive"
  [class.search-animation-running]="searchAnimationState === 'on'"
  [class.search-animation-done]="mobileSearchIsActive && searchAnimationState === 'off'"
>
  <div #navigationContainer class="navigation-container d-flex align-items-center">
    <div class="d-flex align-items-center flex-shrink-0">
      <ng-content select="[fLogoContainer]"></ng-content>
    </div>

    <div class="d-flex align-items-center navigation-controls">
      <div class="selector-container">
        <ng-content select="[fSelectorContainer]"></ng-content>
      </div>

      <div
        #toggleContainer
        class="toggle-container text-truncate"
        [class.d-none]="flexibleTopNav"
        [class.d-md-flex]="flexibleTopNav"
      >
        <ng-content select="[fToggleContainer]"></ng-content>
      </div>
    </div>
  </div>

  <div #searchContainer class="search-container" [ngStyle]="searchContainerStyles">
    <ng-content select="[fSearchContainer]"></ng-content>
    <span class="f-btn-cancel-search d-none link link-primary body-70 ms-3" (click)="closeMobileSearch()">
      {{ cancelSearchBtnTitle$ | async }}
    </span>
  </div>

  <div class="user-actions-container d-flex">
    <div class="entity-container me-2 me-sm-3" [class.d-none]="flexibleTopNav" [class.d-sm-flex]="flexibleTopNav">
      <ng-content select="[fEntityContainer]"></ng-content>
    </div>

    <div class="activity-container me-sm-5" [class.me-3]="!flexibleTopNav">
      <ng-content select="[fActivityContainer]"></ng-content>
    </div>

    <div [class.d-none]="flexibleTopNav" [class.d-sm-flex]="flexibleTopNav">
      <ng-content select="[fUserMenuContainer]"></ng-content>
    </div>
  </div>
</div>
