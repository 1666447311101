import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs';
import { REPORTING_FEATURE_TOGGLE } from './constants';

@Injectable()
export class CanAccessReportingGuard {
  constructor(private router: Router, private store: Store) {}

  canActivate() {
    return this.store.select(getFeatureToggle(REPORTING_FEATURE_TOGGLE)).pipe(
      map(isToggleEnabled => {
        if (!isToggleEnabled) {
          this.router.navigate(['/']);
        }
        return true;
      }),
      take(1)
    );
  }

  canActivateChild() {
    return this.canActivate();
  }
}
