import { createAction, props } from '@ngrx/store';
import { GenerateReportPayload } from '../models/generate-report.payload';
import { SectionSelectorListItem } from '../models/report-sections';

export const saveSectionsSettings = createAction(
  '[Reporting] Save Report Section Settings',
  props<{ sections: SectionSelectorListItem[]; key: string }>()
);

export const saveReportLayoutSettings = createAction(
  '[Reporting] Save Report Layout',
  props<{ layout: string; key: string }>()
);

export const generateReport = createAction('[Reporting] Generate Report', props<GenerateReportPayload>());
