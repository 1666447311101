import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactSearchResults } from '../model/search-contact.model';
import { InstitutionSearchResults } from '../model/search-institution.model';
import { SearchProfileRepository } from '../repository/search-profile.repository';
import { contactsByEmailQuery, contactsQuery, institutionsQuery } from './graphql/queries';
import { EmailQueryVariables, QueryVariables } from './graphql/variables.model';

@Injectable({ providedIn: 'root' })
export class SearchProfileService {
  constructor(private repository: SearchProfileRepository) {}
  private readonly maxTakeNumber = 10;

  public searchInstitutions(criteria: string): Observable<InstitutionSearchResults> {
    const query = this.getFullQuery(institutionsQuery);
    const variables = this.getQueryVariables(criteria);
    return this.repository.searchInstitutions(query, variables);
  }

  public searchContacts(criteria: string): Observable<ContactSearchResults> {
    const query = this.getFullQuery(contactsQuery);
    const variables = this.getQueryVariables(criteria);
    return this.repository.searchContacts(query, variables);
  }

  public searchContactsByEmail(criteria: string): Observable<ContactSearchResults> {
    const query = this.getFullQueryForEmail(contactsByEmailQuery);
    const variables = this.getEmailQueryVariables(criteria);
    return this.repository.searchContacts(query, variables);
  }

  public searchContactsByEmailAndName(criteria: string): Observable<ContactSearchResults> {
    const query = this.getFullQueryForEmail(contactsByEmailQuery);
    const variables = this.getEmailQueryVariables(criteria, false);
    return this.repository.searchContacts(query, variables);
  }

  private getFullQueryForEmail(query: string) {
    return `
    query($filter: String!, $skip: Int!, $take: Int!, $searchByEmail: Boolean, $exactEmailMatch: Boolean, $searchByEmailAndName: Boolean) {
      ${query}
    }`;
  }

  private getFullQuery(query: string) {
    return `
    query($filter: String!, $skip: Int!, $take: Int!) {
      ${query}
    }`;
  }

  private getEmailQueryVariables(criteria: string, searchByEmailOnly = true): EmailQueryVariables {
    return {
      filter: criteria,
      skip: 0,
      take: this.maxTakeNumber,
      searchByEmail: searchByEmailOnly,
      exactEmailMatch: false,
      searchByEmailAndName: !searchByEmailOnly
    };
  }

  private getQueryVariables(criteria: string): QueryVariables {
    return {
      filter: criteria,
      skip: 0,
      take: this.maxTakeNumber
    };
  }
}
