import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContactSupportAdvisor } from '@capital-access/common/user';
import { LoadStatus } from '@capital-access/common/utils';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../common-components.const';

@Component({
  selector: 'ca-contact-support-advisor',
  templateUrl: './contact-support-advisor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSupportAdvisorComponent {
  @Input() csAdvisorData!: ContactSupportAdvisor | null;
  @Input() csAdvisorDataLoadStatus: LoadStatus = LoadStatus.Pristine;

  localizationScope = COMMON_COMPONENTS_LOCALIZATION_SCOPE;

  get isAdvisorDataLoadFailed() {
    return this.csAdvisorDataLoadStatus === LoadStatus.Failed;
  }

  get isAdvisorDataLoaded() {
    return this.csAdvisorDataLoadStatus === LoadStatus.Loaded;
  }
}
