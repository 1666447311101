import { CompanyParticipantsIds } from '@capital-access/common/actions';
import { CompositeId, ProfileId, ProfileType } from '@capital-access/profiles/common';
import { BaseReportRequest } from '../../core/models/report-request.models';
import { ReportSection } from '../../core/models/report-sections';

export interface IncludedContacts {
  companyId: string;
  contactIds: string[];
}

export interface CreateProfileReportRequest extends BaseReportRequest {
  profileIds: string[];
  profileType: string;
  sections: ReportSection[];
  defaultPeerListId?: number;
  includedContacts?: IncludedContacts[];
  combinedSecurityIds?: number[];
}

export class CreateProfileReportDto {
  profiles!: ProfileId[];
  profileType!: ProfileType;
  includedContacts?: IncludedContacts[];
  combinedSecurityIds?: number[];

  static create(args: {
    profiles: { prId?: number | null; crmId?: number | null; name?: string | null }[];
    profileType: 'contact' | 'fund' | 'institution';
    includedContacts?: CompanyParticipantsIds[];
    combinedSecurityIds?: number[];
  }): CreateProfileReportDto {
    return {
      profileType: args.profileType as ProfileType,
      profiles: args.profiles.map(
        p => new ProfileId(new CompositeId(p.prId, p.crmId), args.profileType as ProfileType, p.name)
      ),
      includedContacts: args.includedContacts ? this.formatIncludedContacts(args.includedContacts) : undefined,
      combinedSecurityIds: args.combinedSecurityIds
    };
  }

  private static formatIncludedContacts(includedContacts: CompanyParticipantsIds[]) {
    return includedContacts.map(p => ({
      companyId: new CompositeId(p.companyId.prId, p.companyId.crmId).toString(),
      contactIds: p.contactIds.map(c => new CompositeId(c.prId, c.crmId).toString())
    }));
  }
}
