import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { FireflyDrawerService } from '@capital-access/firefly/components';
import { BaseReportDrawer } from '../../../components/report-drawer/base-report-drawer';
import { ReportSection, SectionSelectorListItem } from '../../../core/models/report-sections';
import { createHistoricalOwnershipReport } from '../../+state/historical-ownership-report.actions';
import { REPORT_FILTERS_FT, SETTINGS_KEY } from '../../constants';
import { CreateHistoricalOwnershipDto } from '../../models/historical-ownership-report.models';

@Component({
  templateUrl: './historical-ownership-report-drawer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoricalOwnershipReportDrawerComponent extends BaseReportDrawer<
  CreateHistoricalOwnershipDto,
  SectionSelectorListItem[]
> {
  get settingsKey(): string {
    return SETTINGS_KEY;
  }

  get createReportAction(): Action {
    return createHistoricalOwnershipReport({
      sections: this.getSelectedSection,
      includeTables: !!this.form.controls[ReportSection.HistoricalOwnershipTableDetails]?.value,
      reportedQuarters: this.request ? this.request.reportedQuarters : [],
      filters: this.request?.filters
    });
  }

  getSections(filteredSections: SectionSelectorListItem[]): SectionSelectorListItem[] {
    return filteredSections;
  }

  constructor(store: Store, drawerService: FireflyDrawerService) {
    super(store, drawerService);
  }

  ReportSection = ReportSection;
  isReportFilterToggleEnabled$ = this.store.select(getFeatureToggle(REPORT_FILTERS_FT));
}
