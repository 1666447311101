import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { Breakpoint } from '@capital-access/firefly/components';
import {
  Contact,
  Error,
  ErrorState,
  Event,
  Fund,
  Institution,
  ResultCategoryMetadata,
  resultsConfig,
  SearchCategory,
  SearchEntity,
  SearchResults,
  SpecialActions
} from './models';
import { ActionConfig, actionsConfig } from './models/actions-config';

const MAX_RECORDS = 10;
const MAX_RECORDS_MOBILE = 20;
const CATEGORY_RECORDS_COUNT = 5;

type EntityWithCompositeKey = Contact | Institution | Fund;

function calculateBuckets(resultCounts: number[]): number[] {
  const totalRecords = resultCounts.reduce((x, y) => x + y, 0);
  const maxRecords = Math.min(totalRecords, MAX_RECORDS);

  const avgCount = Math.floor(maxRecords / resultCounts.length);

  // by default: init buckets with avg number
  const buckets = new Array(resultCounts.length).fill(0).map((_, i) => Math.min(avgCount, resultCounts[i]));

  let recordsAllocated = buckets.reduce((x, y) => x + y, 0);
  while (recordsAllocated < maxRecords) {
    for (let j = 0; j < resultCounts.length; j++) {
      if (buckets[j] < resultCounts[j]) {
        buckets[j]++;
        recordsAllocated++;

        if (recordsAllocated === maxRecords) {
          break;
        }
      }
    }
  }

  return buckets;
}

function mapToCounts(results: SearchResults, filter: SearchCategory | null): number[] {
  const counts = new Array(resultsConfig.length).fill(0);
  for (const item of resultsConfig.filter(i => !filter || filter === i.name)) {
    counts[item.priority] = results[item.name].length;
  }

  return counts;
}

function isEqualEntitiesWithCompositeKey(item1: EntityWithCompositeKey, item2: EntityWithCompositeKey) {
  return (
    (!!item1.crmId && item1.crmId === item2.crmId) ||
    (!!item1.prospectingId && item1.prospectingId === item2.prospectingId)
  );
}

function isEqualEvents(a: Event, b: Event) {
  return a.id === b.id;
}

function mapSelectedName(entity: SearchEntity): string | undefined {
  switch (entity.category) {
    case SearchCategory.Contacts:
      return `${(entity as Contact).firstName} ${(entity as Contact).lastName}`;
    case SearchCategory.Institutions:
      return (entity as Institution).name;
    case SearchCategory.Events:
      return (entity as Event).name;
    case SearchCategory.Funds:
      return (entity as Fund).name;
  }
}

function unselectAllEntities<T extends SearchEntity>(entities: T[]): T[] {
  return entities.map(entity => ({ ...entity, isSelected: false }));
}

export function isEqual(item1: SearchEntity, item2: SearchEntity): boolean {
  if (item1.category === item2.category) {
    switch (item1.category) {
      case SearchCategory.Contacts:
      case SearchCategory.Institutions:
      case SearchCategory.Funds:
        return isEqualEntitiesWithCompositeKey(item1 as EntityWithCompositeKey, item2 as EntityWithCompositeKey);
      case SearchCategory.Events:
        return isEqualEvents(item1 as Event, item2 as Event);
    }
  }
  return false;
}

export function getBuckets(results: SearchResults, filter: SearchCategory | null): number[] {
  if (!filter && isMobile()) {
    return new Array(resultsConfig.length).fill(CATEGORY_RECORDS_COUNT);
  }

  const counts = mapToCounts(results, filter);
  if (filter) {
    return counts;
  }

  return calculateBuckets(counts);
}

function isMobile(): boolean {
  return window.innerWidth < Breakpoint.Sm;
}

export function getSearchCount(): number {
  return isMobile() ? MAX_RECORDS_MOBILE : MAX_RECORDS;
}

export function getSubset<T extends SearchEntity>(results: SearchResults, buckets: number[], property: SearchCategory) {
  const collection = results[property];
  const propertyConfig = resultsConfig.find(x => x.name === property)!;
  const count = buckets[propertyConfig.priority];
  return <T[]>collection.slice(0, count);
}

export function hasMore(results: SearchResults, buckets: number[], property: SearchCategory) {
  const propertyConfig = resultsConfig.find(x => x.name === property)!;
  const count = buckets[propertyConfig.priority];
  return results[property].length > count;
}

export function mapSelectionToResults(searchResults: SearchResults, selectedEntities: SearchEntity[]): SearchResults {
  return {
    criteria: searchResults.criteria,
    contacts: searchResults.contacts.map(contact => ({
      ...contact,
      isSelected: selectedEntities?.some(selected => isEqual(selected, contact)) ?? false
    })),
    institutions: searchResults.institutions.map(institution => ({
      ...institution,
      isSelected: selectedEntities?.some(selected => isEqual(selected, institution)) ?? false
    })),
    funds: searchResults.funds.map(fund => ({
      ...fund,
      isSelected: selectedEntities?.some(selected => isEqual(selected, fund)) ?? false
    })),
    events: searchResults.events.map(event => ({
      ...event,
      isSelected: selectedEntities?.some(selected => isEqual(selected, event)) ?? false
    }))
  };
}

export function showCategoryResult(
  items: SearchEntity[],
  hasSearchingError: boolean,
  category: SearchCategory,
  filter: SearchCategory | null
) {
  return (items && items.length > 0) || filter === category || (hasSearchingError && filter === null);
}

export function isCategorySearchFailed(error: ErrorState, category: SearchCategory): boolean {
  return error.type === Error.Partial && error.failedCategories.includes(category);
}

export function getSpecialActions(selected: SearchEntity[]): ActionConfig[] {
  const selectedCategories = uniq(selected.map(item => item.category));
  return orderBy(
    actionsConfig.filter(action => {
      if (action.hidden) {
        return false;
      }
      const showCategory = selectedCategories.every(category => action.categories.includes(category));
      if (showCategory) {
        setDisabledValue(action, selectedCategories, selected);
      }
      return showCategory;
    }),
    ['priority'],
    ['asc']
  );
}

function setDisabledValue(actionConfig: ActionConfig, selectedCategories: SearchCategory[], selected: SearchEntity[]) {
  if (actionConfig.action === SpecialActions.AddToList) {
    actionConfig.disabled = selectedCategories.length > 1;
  }
  if (actionConfig.action === SpecialActions.AddActivity) {
    actionConfig.disabled = selected.length > 1;
  }
}

export function getAvailableCategories(results: SearchResults): ResultCategoryMetadata[] {
  return orderBy(
    resultsConfig.map(c => ({ ...c, hasItems: results![c.name]?.length > 0 })),
    ['priority'],
    ['asc']
  );
}

export function mapToChipsItems(selectedEntities: SearchEntity[]) {
  return selectedEntities.map(entity => ({
    entity,
    text: mapSelectedName(entity)
  }));
}

export function unselectAllResults(searchResults: SearchResults): SearchResults {
  return {
    criteria: searchResults.criteria,
    contacts: unselectAllEntities(searchResults.contacts),
    institutions: unselectAllEntities(searchResults.institutions),
    events: unselectAllEntities(searchResults.events),
    funds: unselectAllEntities(searchResults.funds)
  };
}
