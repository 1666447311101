<f-list class="pt-0">
  <f-action-buttons
    *ngIf="context.chipItems"
    class="border-bottom"
    [showSelectAll]="context.chipItems.length !== selectedIds.length"
    [showClearAll]="!!selectedIds.length"
    (updateList)="updateListOnActionButtonClick($event)"
  >
  </f-action-buttons>
  <f-list-item-checkbox
    *ngFor="let item of context.chipItems"
    [checked]="isSelected(item[context.idField])"
    (click)="onToggle(item)"
  >
    {{ item | fNestedValue : context.valuePath }}
  </f-list-item-checkbox>
</f-list>
