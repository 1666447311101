import { NgModule } from '@angular/core';
import { provideNavConfig } from '@capital-access/common/navigation';
import { CommonRouterModule } from '@capital-access/common/router';
import { REPORTING_PATH } from './constants';
import { ReportingNavConfigProvider } from './reporting-nav-config.provider';
import { CanAccessReportingGuard } from './reporting-navigation-guard';

export const loadModule = () => import('@capital-access/reporting/client').then(m => m.ReportingClientModule);

// @dynamic
@NgModule({
  imports: [
    CommonRouterModule.forFeature({
      authorized: [
        {
          path: REPORTING_PATH,
          canActivate: [CanAccessReportingGuard],
          loadChildren: loadModule
        }
      ]
    })
  ],
  providers: [provideNavConfig(ReportingNavConfigProvider), CanAccessReportingGuard]
})
export class ReportingClientLazyModule {}
