<ng-container caLocalizationScope="navigation">
  <f-skeleton-drawer *ngIf="(loadCsAdvisorStatus$ | async) === loadStatus.Loading"></f-skeleton-drawer>

  <ca-contact-support-advisor
    [csAdvisorData]="(csAdvisorData$ | async)!"
    [csAdvisorDataLoadStatus]="(loadCsAdvisorStatus$ | async)!"
  >
  </ca-contact-support-advisor>

  <ng-container *ngIf="(loadCsAdvisorStatus$ | async) !== loadStatus.Loading">
    <h4 class="heading-20 mb-3" data-automation-id="client-support-title">{{ 'clientSupport' | caLocalize }}</h4>
    <div class="border-bottom pb-4 mb-4" data-automation-id="contacts-info">
      <div class="mb-2">
        <span class="d-inline-block w-25 text-muted">Americas:</span>
        <a class="link link-main" href="tel:{{ settings.contactSupport.phones.americas }}">{{
          settings.contactSupport.phones.americas
        }}</a>
      </div>

      <div class="mb-2">
        <span class="d-inline-block w-25 text-muted">Europe:</span>
        <a class="link link-main" href="tel:{{ settings.contactSupport.phones.europe }}">{{
          settings.contactSupport.phones.europe
        }}</a>
      </div>

      <div class="mb-2">
        <span class="d-inline-block w-25 text-muted">Asia Pacific:</span>
        <a class="link link-main" href="tel:{{ settings.contactSupport.phones.asiaPacific }}">{{
          settings.contactSupport.phones.asiaPacific
        }}</a>
      </div>

      <div class="mb-4">
        <span class="d-inline-block w-25 text-muted">Japan:</span>
        <a class="link link-main" href="tel:{{ settings.contactSupport.phones.japan }}">{{
          settings.contactSupport.phones.japan
        }}</a>
      </div>

      <div class="mb-2">
        <span class="d-inline-block w-25 text-muted" data-automation-id="contact-email">
          {{ 'email' | caLocalize }}:
        </span>
        <a class="link link-primary" href="mailto:{{ settings.contactSupport.email }}">{{
          settings.contactSupport.email
        }}</a>
      </div>
    </div>

    <div>
      <h4 class="heading-20 mb-3" data-automation-id="helpful-links-title">{{ 'helpfulLinks' | caLocalize }}</h4>
      <p class="mb-3">
        <a class="link link-primary" [href]="settings.licenseAgreement.termsOfUseUrl" target="_blank">
          {{ 'thirdPartyData' | caLocalize }}
        </a>
      </p>
      <p class="mb-3">
        <a class="link link-primary" [href]="settings.licenseAgreement.gcpTermsOfUseUrl" target="_blank">{{
          'termsOfUse' | caLocalize
        }}</a>
      </p>
      <p class="mb-3">
        <a class="link link-primary" [href]="settings.licenseAgreement.privacyPolicyUrl" target="_blank">{{
          'privacyPolicy' | caLocalize
        }}</a>
      </p>
      <p class="mb-3">
        <a class="link link-primary" href="javascript:;" (click)="showDisclaimer()">{{ 'disclaimer' | caLocalize }}</a>
      </p>
      <p>
        <a class="link link-primary" href="javascript:;" (click)="exportContactsWithdrawList()">{{
          'withdrawList' | caLocalize
        }}</a>
      </p>
    </div>
  </ng-container>
</ng-container>

<span class="text-muted body-50 mb-6 ms-6 fixed-bottom" data-automation-id="watermark"
  >© {{ currentYear }} S&P Global. All Rights Reserved</span
>
