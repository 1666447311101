<ng-container [caLocalizationScope]="localizationScope">
  <div class="heading-20 mb-2">{{ 'reportLayout' | caLocalize }}</div>
  <f-single-choice-chip-group
    [items]="(layoutOptions$ | async)!"
    [selectedId]="layout"
    [chipSize]="chipSize"
    (select)="setLayout($event)"
    class="mb-2"
  ></f-single-choice-chip-group>
  <div class="heading-20 mb-2 mt-5">{{ 'sections' | caLocalize }}</div>
  <p class="mb-2">{{ request!.profileType + 'ReportDescription' | caLocalize }}</p>
  <form [formGroup]="form">
    <ca-sections-selector formControlName="sections" (settingsChange)="settingsChange($event)"></ca-sections-selector>
    <ca-set-as-default-toggle
      [control]="setAsDefaultControl"
      [disableSaving]="disableSaving"
      [toggleLabel]="request!.profileType + 'ExtendedToggleLabel'"
    ></ca-set-as-default-toggle>
  </form>
  <ng-template fDrawerFooterTemplate>
    <ca-modify-report-drawer-footer
      [disableSaving]="disableSaving"
      [createReportTitle]="request!.profileType + 'CreateReport'"
      (closeDrawer)="close()"
      (createReport)="createReport()"
    >
    </ca-modify-report-drawer-footer>
  </ng-template>
</ng-container>
