import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs';
import { getFeatureToggle } from './+state/feature-toggle.selectors';

export function getFeatureEnabledGuard(
  featureToggle: string,
  fallbackRoute: string[] = ['/']
): CanActivateFn & CanActivateChildFn {
  return () => {
    const store = inject(Store);
    const router = inject(Router);

    return store.select(getFeatureToggle(featureToggle)).pipe(
      tap(enabled => {
        if (!enabled) {
          router.navigate(fallbackRoute);
        }
      }),
      take(1)
    );
  };
}
