import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyActionButtonsModule } from '../action-buttons';
import { FireflyDropdownModule } from '../dropdowns';
import { FireflyListModule } from '../lists';
import { FireflyPopoverModule } from '../popovers';
import { FireflyFormattingModule } from '../utils/formatting/formatting.module';
import { FireflyChipComponent } from './chip/chip.component';
import { FireflyCollapsedPopoverComponent } from './editable-chip-group/collapsed-popover/collapsed-popover.component';
import { FireflyEditableChipGroupComponent } from './editable-chip-group/editable-chip-group.component';
import { FireflyMultiChoiceChipGroupMobileComponent } from './multi-choice-chip-group/mobile-modal/mobile-modal.component';
import { FireflyMultiChoiceChipGroupComponent } from './multi-choice-chip-group/multi-choice-chip-group.component';
import { FireflySingleChoiceChipGroupComponent } from './single-choice-chip-group/single-choice-chip-group.component';

@NgModule({
  imports: [
    CommonModule,
    FireflyFormattingModule,
    FireflyPopoverModule,
    FireflyDropdownModule,
    FireflyListModule,
    FireflyActionButtonsModule
  ],
  declarations: [
    FireflyChipComponent,
    FireflySingleChoiceChipGroupComponent,
    FireflyEditableChipGroupComponent,
    FireflyMultiChoiceChipGroupComponent,
    FireflyCollapsedPopoverComponent,
    FireflyMultiChoiceChipGroupMobileComponent
  ],
  exports: [
    FireflyChipComponent,
    FireflySingleChoiceChipGroupComponent,
    FireflyEditableChipGroupComponent,
    FireflyMultiChoiceChipGroupComponent
  ]
})
export class FireflyChipModule {}
