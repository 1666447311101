import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from '@progress/kendo-file-saver';
import { catchError, concatMap, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { LocalizationService } from '@capital-access/common/localization';
import { Alert, NotificationService } from '@capital-access/firefly/components';
import { LOCALIZATION_KEY, SECTIONS_SETTINGS_KEY } from '../../constants';
import { CreateReportService } from '../services/create-report.service';
import { generateReport, saveReportLayoutSettings, saveSectionsSettings } from './report.actions';

@Injectable()
export class CreateReportEffects {
  saveSectionsSettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveSectionsSettings),
        withLatestFrom(this.localization.getLocalization(LOCALIZATION_KEY)),
        tap(([action, localization]) => {
          localStorage.setItem(
            `${action.key.toLocaleUpperCase()}_${SECTIONS_SETTINGS_KEY}`,
            JSON.stringify(action.sections)
          );
          this.notificationService.notify(localization[`${action.key}SectionSettingSaved`], {
            type: 'success'
          });
        })
      ),
    { dispatch: false }
  );

  saveSectionsLayout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveReportLayoutSettings),
        tap(action => {
          localStorage.setItem(action.key, action.layout);
        })
      ),
    { dispatch: false }
  );

  generateReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generateReport),
      mergeMap(({ request, url, successMessage, failureMessage, finishAction }) =>
        this.createReportService.createReport(request, url).pipe(
          withLatestFrom(this.localization.localize(successMessage, {}, { scope: LOCALIZATION_KEY })),
          concatMap(([state, success]) => {
            return fetch(state.url)
              .then(r => r.blob())
              .then(response => {
                saveAs(response, state.fileName);
                this.notificationService.notify(success, { type: Alert.Success });
              });
          }),
          catchError(() =>
            this.localization.localize(failureMessage, {}, { scope: LOCALIZATION_KEY }).pipe(
              take(1),
              tap(fail => this.notificationService.notify(fail, { type: Alert.Danger }))
            )
          ),
          map(() => finishAction)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private createReportService: CreateReportService,
    private localization: LocalizationService,
    private notificationService: NotificationService
  ) {}
}
