import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import { of } from 'rxjs';
import { FireflyModalService, ModalRef } from '../../modal';
import { FireflyLocalizationService } from '../../utils';
import { ChipSize } from '../models/chip-size';
import { ChipType } from '../models/chip-type';
import { FireflyMultiChoiceChipGroupMobileComponent } from './mobile-modal/mobile-modal.component';
@Component({
  selector: 'f-multi-choice-chip-group',
  templateUrl: './multi-choice-chip-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyMultiChoiceChipGroupComponent implements OnChanges, OnInit {
  @Input() items: Record<string, unknown>[] | null = [];
  @Input() valuePath: string = 'text';
  @Input() idField: string = 'id';
  @Input() selectedIds: unknown[] = [];
  @Input() size: ChipSize = ChipSize.Xs;
  @Input() groupedField: string = '';
  @Input() disabled = false;
  @Input() dropdownPlacement = 'bottom-left';
  @Input() responsive = false;

  ChipType = ChipType;
  chipItems: Record<string, unknown>[] | null = [];

  private selectedItemsTitle = 'Selected items';
  private selectedItems: Record<string, unknown>[] = [];
  private modal: ModalRef<unknown> | null = null;
  private modalService = inject(FireflyModalService);
  private localizationService = inject(FireflyLocalizationService, { optional: true });
  private destroyRef = inject(DestroyRef);

  get selectedItemsCount$() {
    const count = this.selectedIds.length;
    return this.localizationService
      ? this.localizationService.localize(count === 1 ? 'chipsItemsCountSingular' : 'chipsItemsCountPlural', {
          count: count
        })
      : of(count === 1 ? `${count} item selected` : `${count} items selected`);
  }

  get noItemsSelected$() {
    return this.localizationService
      ? this.localizationService.localize('chipsNoItemsSelected', {})
      : of('No items selected');
  }

  @Output() toggle = new EventEmitter<unknown>();

  @HostBinding('class') class = 'd-flex flex-wrap mt-n2';

  ngOnInit() {
    if (this.localizationService) {
      this.localizationService
        .localize('chipsSelectedItems', {})
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(title => (this.selectedItemsTitle = title));
    }
  }

  ngOnChanges() {
    this.chipItems = this.groupedField ? flatten(Object.values(groupBy(this.items, this.groupedField))) : this.items;
  }

  isSelected(id: unknown) {
    return this.selectedIds.includes(id);
  }

  onToggle(item: Record<string, unknown>) {
    if (this.modal) {
      this.selectedItems.push(item);
      return;
    }
    this.toggle.emit(item);
  }

  updateListOnActionButtonClick(isSelected: boolean) {
    if (!this.chipItems) return;

    this.chipItems.forEach(chipItem => {
      if (this.isSelected(chipItem[this.idField]) !== isSelected) this.toggle.emit(chipItem);
    });
  }

  openModal(): void {
    this.modal = this.modalService.open({
      component: FireflyMultiChoiceChipGroupMobileComponent,
      title: this.selectedItemsTitle,
      context: this,
      mobile: true
    });

    this.modal.result
      .then(
        () => this.handleStateUpdate(),
        () => this.resetState()
      )
      .finally(() => (this.modal = null));
  }

  private handleStateUpdate() {
    this.selectedItems.forEach(item => window.requestAnimationFrame(() => this.toggle.emit(item)));
    this.selectedItems = [];
  }

  private resetState() {
    this.selectedItems = [];
  }
}
