import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyPopoverModule } from '../../../popovers';
import { FireflyBarChartPopoverModule } from '../mobile-popovers/bar-chart-popover/chart-mobile-popover.module';
import { FireflyPositiveNegativeBarSeriesModule } from '../positive-negative-bar-series';
import { FireflyPositiveNegativeGroupedBarSeriesComponent } from './positive-negative-grouped-bar-series.component';

@NgModule({
  imports: [CommonModule, FireflyPopoverModule, FireflyPositiveNegativeBarSeriesModule, FireflyBarChartPopoverModule],
  declarations: [FireflyPositiveNegativeGroupedBarSeriesComponent],
  exports: [FireflyPositiveNegativeGroupedBarSeriesComponent]
})
export class FireflyPositiveNegativeGroupedBarSeriesModule {}
