import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyChartBoilerplateModule } from '../common/chart-boilerplate/chart-boilerplate.module';
import { FireflyPositiveNegativeGroupedBarSeriesModule } from '../common/positive-negative-grouped-bar-series';
import { FireflyPositiveNegativeGroupedBarChartComponent } from './positive-negative-grouped-bar-chart.component';

@NgModule({
  imports: [CommonModule, FireflyPositiveNegativeGroupedBarSeriesModule, FireflyChartBoilerplateModule],
  declarations: [FireflyPositiveNegativeGroupedBarChartComponent],
  exports: [FireflyPositiveNegativeGroupedBarChartComponent]
})
export class FireflyPositiveNegativeGroupedBarChartModule {}
